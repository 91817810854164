'use strict';

angular.module('mvpcarrefourApp')
    .config(function($stateProvider) {
        $stateProvider
            .state('login', {
                url: '/login',
                templateUrl: 'app/account/login/login.html',
                controller: 'LoginController',
                controllerAs: 'vm'
            })
            .state('login.editions', {
                onEnter: function($state) {
                    $state.go('main.editions');
                }
            })
            .state('logout', {
                url: '/logout',
                referrer: 'main',
                template: '',
                controller: function($state, Auth) {
                    Auth.logout();
                    $state.go('main');
                }
            })
            .state('signup', {
                url: '/registro?dst',
                templateUrl: 'app/account/signup/signup.html',
                controller: 'SignupController',
                controllerAs: 'vm',
            })
            .state('change-pwd', {
              url: '/cambiar-password',
              templateUrl: 'app/account/new-password/new-password.html',
              controller: 'newPasswordController',
              controllerAs: 'vm',
              authenticate: true
            })
            .state('reset-pwd', {
              url: '/recuperar-password',
              templateUrl: 'app/account/reset-password/reset-password.html',
              controller: 'ResetPasswordController',
              controllerAs: 'vm'
            })
           
    })
    .run(function($rootScope) {
        $rootScope.$on('$stateChangeStart', function(event, next, nextParams, current) {          
            

            if (next.name === 'logout' && current && current.name && !current.authenticate) {
                next.referrer = current.name;
            }

            if(next.name === 'main') {
                if (!angular.isUndefined($rootScope))
                    if (!angular.isUndefined($rootScope.votePreLogin)) {
                        delete $rootScope.votePreLogin;                       
                    }

            }
        });
    });
